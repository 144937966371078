import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import signupIcon from '../../../assets/person_icon.png';
import SearchIcon from '../SearchIcon';
import { offersFeature } from '../../../config/configOffers';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Modal,
  Button,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onManageDisableScrolling,
    isGuest,
  } = props;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  // SEARCH FOR DESKTOP
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const handleSearchModalOpen = () => {
    setIsSearchModalOpen(true);
  };

  const handleSearchModalClose = () => {
    setIsSearchModalOpen(false);
  };

  const handleSearchSubmit = values => {
    onSearchSubmit(values);
    handleSearchModalClose();
  };

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const signupLink = isAuthenticatedOrJustHydrated ? (
    <Menu>
      <MenuLabel>
        <img src={signupIcon} alt="Signup" className={css.signupIcon} />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InboxPage" params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="OffersPage">
          {offersFeature && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('OffersPage'))}
              name="OffersPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourOffersLink" />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <img src={signupIcon} alt="Signup" className={css.signupIcon} />
    </NamedLink>
  );

  return (
    <nav className={`${classes} ${css.gridContainer}`}>
      <div className={css.flexItemLeft}>
        <div>
          <NamedLink name="LandingPage" className={css.paddedItemFirst}>
            <span className={`${css.createListing} ${css.titleTextStyle}`}>
              <FormattedMessage id="TopbarDesktop.home" />
            </span>
          </NamedLink>

          <NamedLink name="SearchPage" className={css.paddedItemCenter}>
            <span className={`${css.createListing} ${css.titleTextStyle}`}>
              <FormattedMessage id="TopbarDesktop.shop" />
            </span>
          </NamedLink>

          {!isGuest && (
            <NamedLink name="SellHomePage" className={css.paddedItemCenter}>
              <span className={`${css.createListing} ${css.titleTextStyle}`}>
                <FormattedMessage id="TopbarDesktop.sell" />
              </span>
            </NamedLink>
          )}
          <a href="https://infantiumvictoria.de/" className={css.paddedItemLast}>
            <span className={`${css.createListing} ${css.titleTextStyle}`}>
              <FormattedMessage id="TopbarDesktop.brandLink" />
            </span>
          </a>
        </div>
      </div>

      <div className={css.flexItem}>
        <LinkedLogo
          className={css.logoLink}
          layout="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </div>

      <div className={`${css.flexItemRight} ${css.flexRow} ${css.nonSelectable}`}>
        <div className={css.rightItemsContainer}>
          <Button
            className={`${css.buttonNoBackground} ${css.paddedItemFirst}`}
            onClick={handleSearchModalOpen}
          >
            <SearchIcon />
          </Button>
          {!isGuest && signupLink}
        </div>
      </div>
      <Modal
        id="TopbarDesktopSearch"
        isOpen={isSearchModalOpen}
        onClose={handleSearchModalClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <TopbarSearchForm
          onSubmit={handleSearchSubmit}
          initialValues={initialSearchFormValues}
          appConfig={appConfig}
        />
      </Modal>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
