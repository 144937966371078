import { getBrandProcessCash, getDefaultProcessAlias, getOfferTransitions } from './transactionHelpers';

/**
 * Transaction process graph for product orders:
 *   - purchase-cash
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  //Offers transitions
  ...getOfferTransitions(),
 
  // STATE: initial
  // When a customer makes an order for a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',
  REQUEST_PAYMENT_KLARNA_OR_SEPA: 'transition/request-payment-klarna-or-sepa',
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',

  // STATE: pending-payment
  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  CONFIRM_PAYMENT_KLARNA_OR_SEPA: 'transition/confirm-payment-klarna-or-sepa',
  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',
  EXPIRE_AND_VOID_PAYMENT: 'transition/expire-and-void-payment',
  EXPIRE_AND_VOID_PAYMENT_AFTER_INQUIRY: 'transition/expire-and-void-payment-after-inquiry',

  // STATE: purchased
  // Provider or opeartor can mark the product shipped
  MARK_SHIPPED: 'transition/mark-shipped',
  OPERATOR_MARK_SHIPPED: 'transition/operator-mark-shipped',
  // Automatic cancellation happens if noone marks that the item has been shipped
  AUTO_CANCEL: 'transition/auto-cancel',
  AUTO_CANCEL_KLARNA_OR_SEPA: 'transition/auto-cancel-klarna-or-sepa',
  // Operator can cancel the purchase before product has been marked as delivered / received
  CANCEL: 'transition/cancel',

  // STATE: shipped
  // Operator or customer marks that product has been delivered to the customer
  MARK_DELIVERED: 'transition/mark-delivered',
  OPERATOR_MARK_DELIVERED: 'transition/operator-mark-delivered',
  // After the product has been shipped, customer or operator can dispute the transaction
  DISPUTE_FROM_SHIPPED: 'transition/dispute-from-shipped',
  OPERATOR_DISPUTE_FROM_SHIPPED: 'transition/operator-dispute-from-shipped',

  // STATE: delivered
  // Customer has 3 days to accept or dispute the delivered item
  MARK_ACCEPTED: 'transition/mark-accepted',
  // If customer doesn't accept the shipped item manually, it will get accepted automatically after 3 days
  AUTO_MARK_ACCEPTED: 'transition/auto-mark-accepted',
  DISPUTE_FROM_DELIVERED: 'transition/dispute-from-delivered',
  OPERATOR_DISPUTE_FROM_DELIVERED: 'transition/operator-dispute-from-delivered',


  // STATE: disputed
  // If nothing is done to disputed transaction it ends up to Canceled state
  AUTO_CANCEL_FROM_DISPUTED: 'transition/auto-cancel-from-disputed',
  // Operator can cancel disputed transaction manually
  CANCEL_FROM_DISPUTED: 'transition/cancel-from-disputed',
  // Operator can mark the disputed transaction as accepted
  MARK_ACCEPTED_FROM_DISPUTED: 'transition/mark-accepted-from-disputed',

  // STATE: accepted
  // System moves transaction automatically from accepted state to complete state
  // This makes it possible to to add notifications to that single transition.
  AUTO_COMPLETE: 'transition/auto-complete',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */

export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PURCHASED: 'purchased',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  ACCEPTED: 'accepted',
  DISPUTED: 'disputed',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

const cashBrandTransactionProcess = getBrandProcessCash();
const alias = getDefaultProcessAlias();

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: `${cashBrandTransactionProcess}/${alias}`,

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
        [transitions.REQUEST_PAYMENT_KLARNA_OR_SEPA]: states.PENDING_PAYMENT,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
      },
    },

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.EXPIRE_AND_VOID_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.EXPIRE_AND_VOID_PAYMENT_AFTER_INQUIRY]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PURCHASED,
        [transitions.CONFIRM_PAYMENT_KLARNA_OR_SEPA]: states.PURCHASED,
      },
    },

    [states.PAYMENT_EXPIRED]: {},
    [states.PURCHASED]: {
      on: {
        [transitions.MARK_SHIPPED]: states.SHIPPED,
        [transitions.OPERATOR_MARK_SHIPPED]: states.SHIPPED,
        [transitions.AUTO_CANCEL]: states.CANCELED,
        [transitions.AUTO_CANCEL_KLARNA_OR_SEPA]: states.CANCELED,
        [transitions.CANCEL]: states.CANCELED,
      },
    },

    [states.CANCELED]: {},

    [states.SHIPPED]: {
      on: {
        [transitions.MARK_DELIVERED]: states.DELIVERED,
        [transitions.OPERATOR_MARK_DELIVERED]: states.DELIVERED,
        [transitions.DISPUTE_FROM_SHIPPED]: states.DISPUTED,
        [transitions.OPERATOR_DISPUTE_FROM_SHIPPED]: states.DISPUTED,
      },
    },

    [states.DELIVERED]: {
      on: {
        [transitions.MARK_ACCEPTED]: states.ACCEPTED,
        [transitions.AUTO_MARK_ACCEPTED]: states.ACCEPTED,
        [transitions.DISPUTE_FROM_DELIVERED]: states.DISPUTED,
        [transitions.OPERATOR_DISPUTE_FROM_DELIVERED]: states.DISPUTED,
      },
    },

    [states.DISPUTED]: {
      on: {
        [transitions.AUTO_CANCEL_FROM_DISPUTED]: states.CANCELED,
        [transitions.CANCEL_FROM_DISPUTED]: states.CANCELED,
        [transitions.MARK_ACCEPTED_FROM_DISPUTED]: states.ACCEPTED,
      },
    },

    [states.ACCEPTED]: {
      on: {
        [transitions.AUTO_COMPLETE]: states.COMPLETED,
      },
    },

    [states.COMPLETED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.CONFIRM_PAYMENT,
    transitions.CONFIRM_PAYMENT_KLARNA_OR_SEPA,
    transitions.AUTO_CANCEL,
    transitions.CANCEL,
    transitions.MARK_SHIPPED,
    transitions.OPERATOR_MARK_SHIPPED,
    transitions.DISPUTE_FROM_SHIPPED,
    transitions.OPERATOR_DISPUTE_FROM_SHIPPED,
    transitions.MARK_DELIVERED,
    transitions.OPERATOR_MARK_DELIVERED,
    transitions.DISPUTE_FROM_DELIVERED,
    transitions.OPERATOR_DISPUTE_FROM_DELIVERED,
    transitions.MARK_ACCEPTED,
    transitions.AUTO_MARK_ACCEPTED,
    transitions.AUTO_COMPLETE,
    transitions.AUTO_CANCEL_FROM_DISPUTED,
    transitions.CANCEL_FROM_DISPUTED,
    transitions.MARK_ACCEPTED_FROM_DISPUTED,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return false;
};

export const isProviderReview = transition => {
  return false;
};

export const isTransitionCounterByCustomer = transition => {
  return transition === transitions.TRANSITION_COUNTER_OFFER_BY_CUSTOMER;
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [transitions.REQUEST_PAYMENT, transitions.REQUEST_PAYMENT_KLARNA_OR_SEPA, transitions.REQUEST_PAYMENT_AFTER_INQUIRY].includes(
    transition
  );
};
// Check when transaction is completed (item is received)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.AUTO_COMPLETE,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.EXPIRE_AND_VOID_PAYMENT,
    transitions.EXPIRE_AND_VOID_PAYMENT_AFTER_INQUIRY,
    transitions.CANCEL,
    transitions.AUTO_CANCEL,
    transitions.AUTO_CANCEL_KLARNA_OR_SEPA,
    transitions.AUTO_CANCEL_FROM_DISPUTED,
    transitions.CANCEL_FROM_DISPUTED,
  ];
  return txRefundedTransitions.includes(transition);
};


export const statesNeedingProviderAttention = [states.PURCHASED];
