/**
 * Get all transaction process names associated with the brand
 * Note: purchase process uses dashes by convention
 */
export const getBrandProcesses = () => {
  const brandId = process.env.REACT_APP_BRAND_NAME;
  return [
    `purchase-cash-${brandId.replace(/_/g, '-')}`,
    `purchase-voucher-${brandId.replace(/_/g, '-')}`,
  ];
};

/**
 * Get cash transaction process name associated with the brand
 * Note: purchase process uses dashes by convention
 */
export const getBrandProcessCash = () => {
  const brandId = process.env.REACT_APP_BRAND_NAME;
  return `purchase-cash-${brandId.replace(/_/g, '-')}`;
};

/**
 * Get transaction process alias
 */
export const getDefaultProcessAlias = () => {
  return 'prod';
};

export const getOfferTransitions = () => {
  return {
    //Offers transitions 
    TRANSITION_SEND_OFFER: 'transition/send-offer',
    TRANSITION_ACCEPT_OFFER: 'transition/accept-offer',
    TRANSITION_DECLINE_OFFER: 'transition/decline-offer',
    TRANSITION_COUNTER_OFFER: 'transition/counter-offer',
    TRANSITION_COUNTER_OFFER_BY_CUSTOMER: 'transition/counter-offer-by-customer',
    TRANSITION_ACCEPT_AFTER_COUNTER_OFFER: 'transition/accept-after-counter-offer',
    TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER: 'transition/accept-after-counter-by-provider',
    TRANSITION_DECLINE_AFTER_COUNTER_OFFER: 'transition/decline-after-counter-offer',
    TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER: 'transition/decline-after-counter-by-provider',
  };
};
