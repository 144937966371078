// Utility function to replace dynamic placeholders in translation keys and values
export const replaceDynamicValues = (translations, dynamicValue) => {
  const dynamicKeyPart = 'purchase-cash';
  const dynamicValuePart = dynamicValue.replace(/_/g, '-');

  return Object.keys(translations).reduce((acc, key) => {
    const newKey = key.replace(dynamicKeyPart, `purchase-cash-${dynamicValuePart}`);
    acc[newKey] = translations[key];
    return acc;
  }, {});
};